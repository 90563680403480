<template>
  <v-card>
    <v-card-title>Community User Management</v-card-title>
    <v-card-title>
      <v-text-field
        v-model="searchCriteria.text"
        label="Enter Email Id/Mobile/Name"
        single-line
        hide-details
        v-validate="'required'"
        data-vv-name="email id"
        :error-messages="errors.collect('email id')"
      ></v-text-field>
      <v-btn color="primary" @click="searchRespondent">Search</v-btn>
    </v-card-title>

    <div v-if="totalDocs > 0" class="pl-4">
      <p>Total Users: {{ totalDocs }}</p>
    </div>

    <v-data-table :headers="headers" :items="users"> </v-data-table>

    <div class="text-xs-center pt-2">
      <v-pagination
        color="primary"
        v-model="pagination.page"
        :length="Math.ceil(totalDocs / 10)"
        :total-visible="7"
        @input="(pageNumber) => loadUsers(pageNumber)"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
import moment from "moment";
const service = new RestResource();

export default {
  data() {
    return {
      search: "",
      searchCriteria: {},
      pagination: {},
      headers: [
        { text: "User Id", value: "_id", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: true },
        { text: "Email", value: "email", align: "center", sortable: true },
        {
          text: "Mobile Number",
          value: "mobile",
          align: "center",
          sortable: false,
        },
        { text: "Country", value: "country", align: "center", sortable: true },
        {
          text: "Date Of Birth",
          value: "dob",
          align: "center",
          sortable: true,
        },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      users: [],
      totalDocs: 0,
      nextPage: 1,
      userList: {},
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.nextPage = 1;
      this.loadUsers();
    },

    loadUsers(pageNumber) {
      this.userList.page = pageNumber ? pageNumber : this.nextPage;

      this.$setLoader();
      service.getCommunityUsers(this.userList).then((r) => {
        this.users = r.data.users;
        for (let user of this.users) {
          user.dob = moment(String(user.dob)).format("DD/MM/YYYY");
        }
        this.nextPage = r.data.nextPage;
        this.totalDocs = r.data.totalDocs;
        this.$disableLoader();
      });
    },

    searchRespondent() {
      this.$setLoader();
      this.users = [];

      service.findCommunityUserByText(this.searchCriteria).then((r) => {
        this.users = r.data.data;
        for (let user of this.users) {
          user.mobile = user.phoneNumber;
          user.dob = moment(String(user.dob)).format("DD/MM/YYYY");
        }
        this.$disableLoader();
      });
    },
  },
};
</script>
